import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "mb_class"
};
const _hoisted_2 = {
  class: "mb_nav_barbox"
};
const _hoisted_3 = {
  class: "mb_conbox"
};
const _hoisted_4 = {
  class: "leftbox"
};
const _hoisted_5 = {
  class: "rightbox"
};
const _hoisted_6 = {
  class: "classbar",
  id: "scrollbox",
  ref: "scrollbar"
};
const _hoisted_7 = ["id"];
const _hoisted_8 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_sidebar_item = _resolveComponent("van-sidebar-item");
  const _component_van_sidebar = _resolveComponent("van-sidebar");
  const _component_van_grid_item = _resolveComponent("van-grid-item");
  const _component_van_grid = _resolveComponent("van-grid");
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_nav_bar, {
    "left-arrow": "",
    onClickLeft: $setup.onClickLeft,
    title: "分类",
    onClickRight: $setup.onClickRight
  }, {
    right: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "weapp-nav",
      size: "24"
    })]),
    _: 1
  }, 8, ["onClickLeft", "onClickRight"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_van_sidebar, {
    modelValue: $setup.activeKey,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.activeKey = $event),
    onChange: $setup.changeNav
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cardTitle, (item, index) => {
      return _openBlock(), _createBlock(_component_van_sidebar_item, {
        title: item.cat_title,
        key: index
      }, null, 8, ["title"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "onChange"])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cardUl, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "gridlist",
      key: index,
      id: 'scroll' + index
    }, [_createElementVNode("p", _hoisted_8, _toDisplayString(item.cat_title), 1), _createVNode(_component_van_grid, {
      "column-num": 3,
      border: false,
      class: "mb_gridlist"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cardUl[index].children, (itemli, indexli) => {
        return _openBlock(), _createBlock(_component_van_grid_item, {
          key: indexli,
          icon: itemli.cat_icon,
          text: itemli.cat_text
        }, null, 8, ["icon", "text"]);
      }), 128))]),
      _: 2
    }, 1024)], 8, _hoisted_7);
  }), 128))], 512)])]), _createVNode(_component_van_tabbar, {
    modelValue: $setup.active,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.active = $event),
    "active-color": "#017DFF",
    "inactive-color": "#666666"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
      icon: "wap-home",
      name: "mb-home",
      replace: "",
      to: "mb-home"
    }, {
      default: _withCtx(() => [_createTextVNode("首页")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "bars",
      name: "mb-class",
      replace: "",
      to: "mb-class"
    }, {
      default: _withCtx(() => [_createTextVNode("分类")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "bookmark",
      name: "mb-equit",
      replace: "",
      to: "mb-equity"
    }, {
      default: _withCtx(() => [_createTextVNode("权益")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "manager",
      name: "mb-ours",
      replace: "",
      to: "mb-ours"
    }, {
      default: _withCtx(() => [_createTextVNode("我的")]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])]);
}