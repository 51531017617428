import { ref, watch } from 'vue';
import { Toast } from 'vant';
import 'vant/es/toast/style';
import { useWindowSize } from '@vant/use';
export default {
  setup() {
    const onClickLeft = () => history.back();
    const onClickRight = () => Toast('查看更多');
    const active = ref('mb-class');
    const activeKey = ref(0);
    // 临时：点击轻提示；应做：点击滚动至对应位置
    const changeNav = index => Toast(`标签名 ${index + 1}`);
    const {
      width,
      height
    } = useWindowSize();
    console.log(width.value);
    return {
      active,
      onClickLeft,
      onClickRight,
      activeKey,
      changeNav
    };
  },
  data() {
    return {
      cardTitle: [{
        cat_title: '常用'
      }, {
        cat_title: '查询'
      }, {
        cat_title: '套餐'
      }, {
        cat_title: '流量'
      }, {
        cat_title: '权益会员'
      }, {
        cat_title: '宽带电视'
      }, {
        cat_title: '手机商城'
      }, {
        cat_title: '家庭'
      }, {
        cat_title: '号卡'
      }, {
        cat_title: '充值'
      }, {
        cat_title: '5G'
      }, {
        cat_title: '基础服务'
      }],
      cardUl: [{
        cat_title: '常用',
        "children": [{
          cat_icon: 'sign',
          cat_text: '7天流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '1元流量月包'
        }, {
          cat_icon: 'sign',
          cat_text: '居家流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '套餐优惠'
        }, {
          cat_icon: 'sign',
          cat_text: '宽带办理'
        }, {
          cat_icon: 'sign',
          cat_text: '1元会员'
        }]
      }, {
        cat_title: '套餐',
        "children": [{
          cat_icon: 'sign',
          cat_text: '7天流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '1元流量月包'
        }, {
          cat_icon: 'sign',
          cat_text: '居家流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '套餐优惠'
        }, {
          cat_icon: 'sign',
          cat_text: '宽带办理'
        }, {
          cat_icon: 'sign',
          cat_text: '1元会员'
        }]
      }, {
        cat_title: '流量',
        "children": [{
          cat_icon: 'sign',
          cat_text: '7天流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '1元流量月包'
        }, {
          cat_icon: 'sign',
          cat_text: '居家流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '套餐优惠'
        }, {
          cat_icon: 'sign',
          cat_text: '宽带办理'
        }, {
          cat_icon: 'sign',
          cat_text: '1元会员'
        }]
      }, {
        cat_title: '权益会员',
        "children": [{
          cat_icon: 'sign',
          cat_text: '7天流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '1元流量月包'
        }, {
          cat_icon: 'sign',
          cat_text: '居家流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '套餐优惠'
        }, {
          cat_icon: 'sign',
          cat_text: '宽带办理'
        }, {
          cat_icon: 'sign',
          cat_text: '1元会员'
        }]
      }, {
        cat_title: '宽带电视',
        "children": [{
          cat_icon: 'sign',
          cat_text: '7天流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '1元流量月包'
        }, {
          cat_icon: 'sign',
          cat_text: '居家流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '套餐优惠'
        }, {
          cat_icon: 'sign',
          cat_text: '宽带办理'
        }, {
          cat_icon: 'sign',
          cat_text: '1元会员'
        }]
      }, {
        cat_title: '手机商城',
        "children": [{
          cat_icon: 'sign',
          cat_text: '7天流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '1元流量月包'
        }, {
          cat_icon: 'sign',
          cat_text: '居家流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '套餐优惠'
        }, {
          cat_icon: 'sign',
          cat_text: '宽带办理'
        }, {
          cat_icon: 'sign',
          cat_text: '1元会员'
        }]
      }, {
        cat_title: '家庭',
        "children": [{
          cat_icon: 'sign',
          cat_text: '7天流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '1元流量月包'
        }, {
          cat_icon: 'sign',
          cat_text: '居家流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '套餐优惠'
        }, {
          cat_icon: 'sign',
          cat_text: '宽带办理'
        }, {
          cat_icon: 'sign',
          cat_text: '1元会员'
        }]
      }, {
        cat_title: '号卡',
        "children": [{
          cat_icon: 'sign',
          cat_text: '7天流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '1元流量月包'
        }, {
          cat_icon: 'sign',
          cat_text: '居家流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '套餐优惠'
        }, {
          cat_icon: 'sign',
          cat_text: '宽带办理'
        }, {
          cat_icon: 'sign',
          cat_text: '1元会员'
        }]
      }, {
        cat_title: '充值',
        "children": [{
          cat_icon: 'sign',
          cat_text: '7天流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '1元流量月包'
        }, {
          cat_icon: 'sign',
          cat_text: '居家流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '套餐优惠'
        }, {
          cat_icon: 'sign',
          cat_text: '宽带办理'
        }, {
          cat_icon: 'sign',
          cat_text: '1元会员'
        }]
      }, {
        cat_title: '5G',
        "children": [{
          cat_icon: 'sign',
          cat_text: '7天流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '1元流量月包'
        }, {
          cat_icon: 'sign',
          cat_text: '居家流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '套餐优惠'
        }, {
          cat_icon: 'sign',
          cat_text: '宽带办理'
        }, {
          cat_icon: 'sign',
          cat_text: '1元会员'
        }]
      }, {
        cat_title: '基础服务',
        "children": [{
          cat_icon: 'sign',
          cat_text: '7天流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '1元流量月包'
        }, {
          cat_icon: 'sign',
          cat_text: '居家流量包'
        }, {
          cat_icon: 'sign',
          cat_text: '套餐优惠'
        }, {
          cat_icon: 'sign',
          cat_text: '宽带办理'
        }, {
          cat_icon: 'sign',
          cat_text: '1元会员'
        }]
      }]
    };
  }
};